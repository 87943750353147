import React from 'react';

function IntroBlock(props){
  switch(props.activeLang){
  case "fr":
  return(<div className="homePage-intro" tabIndex="0"><div className="wrap"><h3>Comment fonctionne TrackMyDown</h3><p>ALLIED a développé TrackMyDown pour que vous puissiez mieux comprendre les produits en duvet de première qualité qui vous tiennent chaud. Il est important de savoir d&#8217;où vient votre duvet et d&#8217;être assuré qu&#8217;il provient d&#8217;une source responsable.<br/><br/>Avec TrackMyDown, vous avez désormais la possibilité de mieux comprendre toutes les nuances du duvet et de prendre une décision plus éclairée en fonction des performances et de la durabilité de l&#8217;isolant exact contenu dans le&nbsp;produit.
</p></div></div>);  
  default:  
  return(<div className="homePage-intro" tabIndex="0"><div className="wrap"><h3>How TrackMyDown Works</h3><p>ALLIED has developed TrackMyDown so you can better understand the premium down products keeping you warm. It&#39;s important to know where your down has come from and to be assured that it has been responsibly&nbsp;sourced. <br/><br/> With TrackMyDown you now have the power to better understand all the nuances of down and make a more-informed decision based on the performance and sustainability of the exact insulation inside the&nbsp;product.</p></div></div>);
  
  }
}  

export default IntroBlock;