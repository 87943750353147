import React from 'react';

function EIContent(props){
return(<><p>HELP OFFSET YOUR PURCHASE BY BECOMING A POW&nbsp;MEMBER.</p><p>Down is one of the lowest impact ingredients in the entire textile industry even when just considering it’s cradle to gate; and we all know how long down products can last. While alternative synthetic counterparts start to lose performance in a matter of a season or two, down can last for decades. And while a recently published <a id="lca-textLink" href="https://idfb.net/index.php?eID=dumpFile&t=f&f=1333&token=492bc3e93df24b16f061b81f0b45aec2bc0fb517" target="_blank" rel="noopener noreferrer">life cycle assessment</a> has shown that in general, down has a 85 - 97% LOWER environmental impact (from cradle to gate) when compared to a recycled synthetic fiber, we know that all materials still have an impact on our planet.</p>

<p>We all need to do our part to mitigate our choices, but too many brands and companies rely too quickly on offsets that have questionable benefits.</p>

<p>This is why we chose to partner with Protect Our Winters.<br/><br/>If you wish to help mitigate the impact of your purchase here, please <a id="pow-textLink" href="//protectourwinters.org/join-team-pow/?channel=tmd_afd" target="_blank" rel="noopener noreferrer">click here to become a POW member</a>. Protect Our Winters focuses on changing policy and until we do that, simply planting more trees will only be a band-aid to the compound fracture that the earth is suffering&nbsp;from.</p></>)
}

function EIContentFR(){
return(<><p>AIDEZ À COMPENSER VOTRE ACHAT EN DEVENANT UN MEMBRE&nbsp;DU&nbsp;POW.</p><p>Le duvet est l&#39;un des ingrédients dont l&#39;impact est le plus faible dans l&#39;ensemble de l&#39;industrie textile, même si l&#39;on tient compte de son cycle de vie, et nous savons tous combien les produits en duvet peuvent durer. Alors que les produits synthétiques alternatifs commencent à perdre leurs performances en l&#39;espace d&#39;une saison ou deux, le duvet peut durer des décennies. Et bien qu&#39;une <a id="lca-textLink" href="https://idfb.net/index.php?eID=dumpFile&t=f&f=1333&token=492bc3e93df24b16f061b81f0b45aec2bc0fb517" target="_blank" rel="noopener noreferrer">évaluation du cycle de vie</a> récemment publiée ait montré qu&#39;en général, le duvet a un impact environnemental de 85 à 97 % MOINS élevé (du berceau à la porte) que celui d&#39;une fibre synthétique recyclée, nous savons que tous les matériaux ont un impact sur notre planète.</p>
<p>Nous devons tous faire notre part pour atténuer nos choix, mais trop de marques et d'entreprises s'en remettent trop rapidement à des compensations dont les avantages sont discutables.</p>
<p>C&#39;est pourquoi nous avons choisi de nous associer à Protect Our Winters.</p>
<p>Si vous souhaitez contribuer à atténuer l&#39;impact de votre achat ici, veuillez <a id="pow-textLink" href="//protectourwinters.org/join-team-pow/?channel=tmd_afd" target="_blank" rel="noopener noreferrer">cliquer ici pour devenir membre de POW</a>. Protect Our Winters se concentre sur le changement de politique et tant que nous ne l&#39;aurons pas fait, planter simplement plus d&#39;arbres ne sera qu&#39;un pansement sur la fracture complexe dont souffre la terre.</p>
</>)
}

function SpecificSustainability(props){  
  switch(props.activeLang){
    case "fr":	    
    return <EIContentFR />;
    default:
    return <EIContent activeLot={ props.activeLot } />;
  }
}

const Sustainability = (props) => {   
	let cleanTitle;
	
	switch(props.activeLang){
    case "fr":	    
    cleanTitle = "L'IMPACT ENVIRONNEMENTAL";
    break;
    default:
    cleanTitle = "ENVIRONMENTAL IMPACT";
    break;
    }
  
    return (<div className="content-ac su hide">
    <input
      className="ac-input top"
      id="ac-7afd"
      name="ac-7afd"
      data-id="2400"
      data-slug="SUSTAINABILITY" //needs to match the state.active
      type="checkbox"
      onChange={ () => props.changeWord('su clicked') }
    />
    <label className="ac-label" htmlFor="ac-7afd">{ cleanTitle }</label>
    <article className="ac-text ac-7afd">
      <div className="single-post-interior" tabIndex="0">
      <SpecificSustainability
        activeLang = {props.activeLang}
        activeLot  = {props.activeLot}
      />
      </div>
    </article>
  </div>
);    
}

export default Sustainability;