import React from 'react';

function FPContentFR(){
  return(<>
  <p>Le pouvoir de remplissage est une mesure de volume qui définit la quantité d&#39;air qu&#39;une quantité donnée de duvet peut piéger. Ce &quot;loft&quot; est mesuré en pouces cubes par 30 grammes de duvet et sert à déterminer la qualité d&#39;isolation du duvet. Plus le Fill Power est élevé, plus le rapport chaleur/poids sera important.</p><p>Lorsque le duvet est vendu pour la fabrication, un Fill Power moyen est désigné pour la vente. Grâce au processus de fabrication et à une manipulation simple, le matériau peut perdre une petite partie de son gonflant d&#39;origine et nous le comprenons. Chez ALLIED, notre engagement à fournir le meilleur produit au monde signifie que nous fournirons toujours un matériau qui teste au-delà de ce qui a été initialement cité pour tenir compte de cette perte lors de la fabrication. Notre objectif est que le produit de nos partenaires atteigne ou dépasse le pouvoir de remplissage commercialisé, même après fabrication et&nbsp;expédition.</p><p>Nous sommes en mesure de vérifier le pouvoir de remplissage réel de chaque lot afin que vous puissiez voir exactement ce qui, dans votre produit, vous garde en sécurité, à l&#39;aise et au chaud.</p>
  </>)
}

function FPContent(){
  return(<>
  <p>Fill Power is a measurement of volume that defines the amount of air a given amount of down can trap. This “loft” is measured in cubic inches per 30 grams of down and is used to determine the insulating quality of the down. The higher the Fill Power, the greater the warmth-to-weight ratio will be.</p><p>When down is sold for manufacturing, an average Fill Power is designated for sale. Through the manufacturing process and simple handling, the material can lose a small amount of its original loft and we understand that. At ALLIED, our commitment to providing the finest product in the world means we will always provide material that tests beyond that initially quoted to account for this loss through manufacturing. It’s our goal that our partners’ product meet or surpass the marketed fill power even after manufacturing and shipping.</p><p>We are able to verify the actual Fill Power of each lot so you can see exactly what is in your product that is keeping you safe, comfortable and warm.</p>
  </>)
}


const FillPower = (props) => {   
	let cleanFPTitle;
	let cleanFPContent;
	
	switch(props.activeLang){
	  case "fr":
	    cleanFPTitle = "POUVOIR DE REMPLISSAGE";
	  break;
	  default:
	    cleanFPTitle = "FILL POWER";
	  break;
	}
	
	switch(props.activeLang){
	  case "fr":	    	    
	    cleanFPContent = FPContentFR();
	  break;
	  default:
	    cleanFPContent = FPContent();
	  break;
	}
			
    return (<div className={`content-ac fp hide`}>
	      <input className="ac-input top" id="ac-3afd" name="ac-3afd" data-id="2400" 
	        data-slug="FP" type="checkbox" 
	        onChange={ () => props.changeWord(this, 'fp clicked') }
	      />
		<label className="ac-label" htmlFor="ac-3afd">{ cleanFPTitle }</label>
		<article className="ac-text ac-3afd"><div className="single-post-interior" tabIndex="0">
			<table><tbody>
			  <tr><td>VERIFIED</td><td>{ props.vFP }FP</td></tr>
			  <tr><td>STATED</td><td>{ props.sFP }FP</td></tr>
			</tbody></table>
			{ cleanFPContent }
			<footer></footer>
		</div></article>
	    </div>);    
}

export default FillPower;

//Lorsque le duvet est vendu pour la fabrication, un Fill Power moyen est désigné pour la vente. Grâce au processus de fabrication et à une manipulation simple, le matériau peut perdre une petite partie de son gonflant d&#39;origine et nous le comprenons. Chez ALLIED, notre engagement à fournir le meilleur produit au monde signifie que nous fournirons toujours un matériau qui teste au-delà de ce qui a été initialement cité pour tenir compte de cette perte lors de la fabrication. Notre objectif est que le produit de nos partenaires atteigne ou dépasse le pouvoir de remplissage commercialisé, même après fabrication et expédition.

//Nous sommes en mesure de vérifier le pouvoir de remplissage réel de chaque lot afin que vous puissiez voir exactement ce qui, dans votre produit, vous garde en sécurité, à l&#39;aise et au chaud.