//firebase
import { initializeApp } from 'firebase/app';
import { getFirestore, doc, getDoc } from 'firebase/firestore';

const firebaseApp = initializeApp({
  apiKey: "AIzaSyBCBmQ1b29WiJFz77ZqM4tRDbJEY_JIIO4",
  authDomain: "trackmydown.firebaseapp.com",
  projectId: "trackmydown",
  storageBucket: "trackmydown.appspot.com",
  messagingSenderId: "186705064419",
  appId: "1:186705064419:web:972f7953b3055b75f6b0d6",
  measurementId: "G-XSWBSP3KGF"
});

const firestore = getFirestore();
let specialOftheDay; 
let linkStatus;
let treatmentStatus;
let rdsStatus;

function doAllTheAmazingThings(data){	
	treatmentStatus = "";
	rdsStatus = "";
	linkStatus = false;
/*
	if(data.prefix == '1602'){
	  linkStatus = 'https://plumeriabay.com?ref=afd-tmd';	
	}
*/
	//rds
	//if(data.rds){rdsStatus = "Certification - RDS"}else{rdsStatus = "";}
	if(true){rdsStatus = "Certification - RDS"}else{rdsStatus = "";}

	//treatment
/*
	switch(data.treatment){
	  case "HyperDry":
	    treatmentStatus = data.Treatment;
	  break;
	  default:
	  //console.log(data.Treatment);
	}
*/
}

export function getUsefulContents(url){
  doAllTheAmazingThings(url);
}

export function getUsefulContentsAlt(docData){  
  treatmentStatus = "";
  rdsStatus = "";
  linkStatus = false;
 
  //rds
  //let rdsFlag = docData.fields.rds.booleanValue;  
  let rdsFlag = true;  
  if(typeof rdsFlag !== 'undefined'){rdsStatus = "Certification - RDS"}else{rdsStatus = false;}

}

export async function brandList(query){
	specialOftheDay = doc(firestore, 'afd/brands/'+query+'/content/');
	const mySnapshot = await getDoc(specialOftheDay);
	
	if(mySnapshot.exists()){
		//display info from firestore				
		const prefixData = mySnapshot.data();	
		return prefixData;
		
	}else{
		console.log("prefix nout found");
	}
}
	
export { linkStatus, treatmentStatus, rdsStatus}