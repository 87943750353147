import React from 'react';

function China(){
  return(<><p>ALLIED has facilities all over the world in strategic locations to source the finest possible materials and best serve all our partners. We have two facilities in China which allows ALLIED to implement our own proprietary processing methods and to ensure the highest quality.</p><p>These facilities recycle approximately 65 Million Gallons of water annually and are bluesign certified.</p></>);
}

function ChinaFR(){
  return(<><p>ALLIED possède des installations dans le monde entier, à des endroits stratégiques, afin de s&#39;approvisionner en matériaux de la meilleure qualité possible et de servir au mieux tous nos partenaires. Nous disposons de deux installations en Chine, ce qui permet à ALLIED de mettre en œuvre ses propres méthodes de traitement exclusives et de garantir la plus haute qualité.</p><p>Ces installations recyclent environ 246 millions de litres d&#39;eau par an et sont certifiées bluesign.</p></>);
}

function LA(){
  return(<><p>Our Los Angeles headquarters is where the bulk of our European sourced material is processed in our proprietary method. It&#8217;s also where our R&amp;D team is located, constantly studying the structure and composition of down to develop new technologies to make down - already the best insulation on the planet - higher performing with even less environmental impact. This facility recycles approximately 40 million gallons of water annually, is bluesign® certified and has a zero discharge of hazardous chemicals policy.</p></>);
}

function LAFR(){
  return(<><p>C&#39;est à notre siège de Los Angeles que la majeure partie de notre matériel d&#39;origine européenne est traitée selon notre méthode exclusive. C&#39;est également là que se trouve notre équipe de R&amp;D, qui étudie en permanence la structure et la composition du duvet afin de mettre au point de nouvelles technologies permettant de rendre le duvet - qui est déjà le meilleur isolant de la planète - plus performant tout en ayant un impact environnemental encore plus faible. Cette installation recycle environ 40 millions de gallons d&#39;eau par an, est certifiée bluesign® et a une politique de zéro rejet de produits chimiques&nbsp;dangereux.</p></>);
}

function CA(){
  return(<><p>ALLIED offers our partners global production options and unequaled diversity through ownership and operation of production facilities on three continents. Our global footprint not only allows us to be more diversified in times of crisis, but allows us to also reduce our overall environmental impact by saving on shipping and transportation. In addition to the down production capabilities in Anhui (China), Hangzhou (China), Hanoi (Vietnam), Kamyk (Czech Republic), Montreal (Canada) and our US headquarters just east of downtown Los Angeles, we also have sourcing offices throughout China and Europe as well as supply chain partners in Hungary and sales offices in Seoul, Korea and Tokyo, Japan. ALLIED also maintains full service bedding manufacturing facilities in Los Angeles, North Carolina, Montreal and Hangzhou through ALLIED Home.</p></>);
}

function CAFR(){
  return(<><p>ALLIED offre à ses partenaires des options de production globales et une diversité inégalée grâce à la propriété et à l'exploitation d'installations de production sur trois continents. Notre empreinte mondiale nous permet non seulement d'être plus diversifiés en temps de crise, mais aussi de réduire notre impact global sur l'environnement en économisant sur les frais d'expédition et de transport. Outre les capacités de production de duvet à Anhui (Chine), Hangzhou (Chine), Hanoi (Vietnam), Kamyk (République tchèque), Montréal (Canada) et notre siège social américain situé à l'est du centre-ville de Los Angeles, nous disposons également de bureaux d'approvisionnement en Chine et en Europe, de partenaires de la chaîne d'approvisionnement en Hongrie et de bureaux de vente à Séoul, en Corée, et à Tokyo, au Japon. ALLIED possède également des usines de fabrication de literie à Los Angeles, en Caroline du Nord, à Montréal et à Hangzhou par l'intermédiaire d'ALLIED Home.</p></>);
}

function SpecificLocation(props){
  switch(props.area){	  	   
    case "Los Angeles":
	switch(props.activeLang){
	  case "fr":	    
	  return <LAFR />;
	  default:
	  return <LA />;
	}	  
	case "Montreal":
    switch(props.activeLang){
    case "fr":	    
    return <CAFR />;
    default:
    return <CA />;
    } 
	case "THE USA":
	switch(props.activeLang){
	  case "fr":	    
	  return <LAFR />;
	  default:
	  return <LA />;
	} 
	 
	default:
	switch(props.activeLang){
	  case "fr":	    
	  return <ChinaFR />
	  default:
	  return <China />
	}	  
  }  
}

const Processing = (props) => {   	
  let cleanTitle; 
  
  switch(props.activeLang){
  case "fr":	    	    
  cleanTitle = "INSTALLATION DE TRAITEMENT";
  break;
  default:
  cleanTitle = "PROCESSING FACILITY"; 	
  break;
  } 
  	  
  if(props.loc){
    return (<div className="content-ac pr hide">
    <input
      className="ac-input top"
      id="ac-10afd"
      name="ac-10afd"
      data-id="2400"
      data-slug="PROCESSING"
      type="checkbox"
      onChange={ () => props.changeWord('processing clicked') }
      ref={props.inputPrRef}
    />
    <label className="ac-label" htmlFor="ac-10afd">{ cleanTitle }</label>
    <article className="ac-text ac-10afd"><div className="single-post-interior" tabIndex="0">
      <SpecificLocation
        activeLang = { props.activeLang }
        area 	   = { props.loc } 
      />
      </div>
      <footer></footer>
	</article>
	</div>);	
    }else{
	    return(<></>)
    }
}

export default Processing;