import React from 'react';

function contentEN(){
  return(<><p>As a natural product, it is normal to have some dark clusters even in the whitest of down. Others may bleach the material to get rid of these dark spots, but at ALLIED, we do not believe in this method. The harsh bleach will strip the down of its essential oils and can be incredibly harmful to the environment.</p><p>When you are looking at the jacket, keep in mind the math. We can provide our partners 99.9% white down, but each gram of down can contain five to seven thousand down clusters. A lightweight jacket might use around 100g of down. This means that even with the whitest natural down available, there may several hundred darker clusters per jacket.</p><p>It’s also perfectly normal to occasionally see a few small pieces of down fiber to come through the fabric and seams. If you see this happening, do not pull it out, but try to gently massage the down back into the baffle.</p></>)
}

function contentFR(){
  return(<><p>En tant que produit naturel, il est normal d&#39;avoir quelques grappes sombres même dans le plus blanc des duvets. D&#39;autres peuvent blanchir le matériau pour se débarrasser de ces taches sombres, mais chez ALLIED, nous ne croyons pas à cette méthode. L&#39;eau de Javel, très dure, dépouille le duvet de ses huiles essentielles et peut être incroyablement nocive pour l&#39;environnement. </p><p>Lorsque vous regardez la veste, n&#39;oubliez pas de faire des calculs. Nous pouvons fournir à nos partenaires 99,9% de duvet blanc, mais chaque gramme de duvet peut contenir de cinq à sept mille grappes de duvet. Une veste légère peut utiliser environ 100 g de duvet. Cela signifie que même avec le duvet naturel le plus blanc disponible, il peut y avoir plusieurs centaines de grappes plus foncées par veste. </p><p>Il est également tout à fait normal de voir de temps en temps quelques petits morceaux de fibres de duvet passer à travers le tissu et les coutures. Si vous voyez cela se produire, ne le retirez pas, mais essayez de masser doucement le duvet pour le remettre dans la chicane.</p></>)
}

function CMContent(props){    
switch(props.activeLang){
  case "fr":
  return contentFR();      
  default:
  return contentEN();
}
}
  
const CM = (props) => {   		
	let cmTitle;
	switch(props.activeLang){
	case "fr":
	cmTitle = "NOTES SUR LA COULEUR + MIGRATION";
	break;
  
	default:
	cmTitle = "NOTES ON COLOR + MIGRATION"
	break;
	}

    return (<div className="content-ac cm hide">
    <input
      className="ac-input top"
      id="ac-11afd"
      name="ac-11afd"
      data-id="2400"
      data-slug="CM"
      type="checkbox"
      onChange={ () => props.changeWord('cm clicked') }
    />
    <label className="ac-label" htmlFor="ac-11afd">{ cmTitle }</label>
    <article className="ac-text ac-10afd">
      <div className="single-post-interior" tabIndex="0">
        <CMContent 
          activeLang = {props.activeLang}
        />
      </div>
      <footer></footer>
    </article>
  </div>
);    
}

export default CM;