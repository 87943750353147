import React from 'react';


function HyperDryFR(props){
return(<><p>HyperDry<br/>Fluourocarbon Free Water Resistant Down</p><p>Le duvet contenu dans ce produit a été traité avec la technologie exclusive ALLIED de résistance à l&#39;eau sans fluorocarbone pour vous garder au chaud et au sec, quelles que soient les conditions&nbsp;météorologiques.</p><p><a href="//alliedfeather.com/innovation/hyperdry?ref=tmd">plus d&#39;infos >></a></p></>)	
}

function HyperDry(props){
return(<><p>HyperDry<br/>Fluourocarbon Free Water Resistant Down</p><p>The down inside this product has been treated with ALLIED&#8217;s proprietary fluorocarbon free water resistant technology to keep you warm and dry no matter what the weather has in store.</p><p><a href="//alliedfeather.com/innovation/hyperdry?ref=tmd">more info >></a></p></>)
}
 
function ExpeDryFR(){
  return(<>
    <p>ExpeDry<br/>Quick Drying Down featuring FUZE® technology</p>
    <p>Une nouvelle innovation disruptive sans produits chimiques pour tous les produits en duvet utilisés dans des climats extrêmes et humides.</p>    
    <p>ExpeDRY est une nouvelle alternative sans produits chimiques au duvet hydrophobe traité par WR. Il fonctionne grâce à une liaison permanente et non chimique de particules d&#39;or non toxiques au faisceau de duvet, ce qui facilite l&#39;évaporation des molécules d&#39;eau et permet au duvet de sécher plus&nbsp;rapidement.</p>
    <p>L&#39;évaporation est provoquée par la vibration de la molécule d&#39;eau qui se produit généralement avec l&#39;application de chaleur. Les particules d&#39;or attachées à la grappe de duvet génèrent une "excitation" similaire dans les molécules d&#39;eau sans ajout de chaleur, les faisant vibrer comme si elles étaient chauffées et accélérant finalement le processus d&#39;évaporation.</p>
    <p>Cela permet de créer une chambre isolante plus sèche, car le taux d&#39;évaporation accru est supérieur au taux d&#39;absorption du duvet dans toutes les applications du monde réel. Cela permet également d&#39;éliminer l&#39;humidité interne, telle que la transpiration lors d&#39;activités à haute énergie, ce qui peut conduire à une chaleur plus&nbsp;régulée.</p>
    <p><a href="//alliedfeather.com/info-room/products-innovations/expedry?ref=tmd-com">plus d&#39;infos >></a></p>
  </>)
}

function ExpeDry(){
  return(<>
    <p>ExpeDry<br/>Quick Drying Down featuring FUZE® technology</p>
    <p>A new disruptive chemical-free innovation for all down products used in extreme and wet climates.</p>
    <p>ExpeDRY is a new chemical free alternative to WR treated hydrophobic down. It works by a permanent and chemical-free bonding of non-toxic gold particles to the down cluster which aids in the evaporation of water molecules causing the down to dry faster.</p>
    <p>Evaporation is caused through the vibration of the water molecule which usually happens with the application of heat. The gold particles attached to the down cluster generate a similar “excitement” in the water molecules without added heat causing them to vibrate as if they were heated and ultimately speeding up the process of&nbsp;evaporation.</p>
    <p>This helps to create a drier insulative chamber as the rate of increased evaporation is greater than the absorption rate of down in all real world applications. It also helps with moisture from within such as sweat in high energy activities which could ultimately lead to a more regulated&nbsp;Warmth.</p>
    <p><a href="//alliedfeather.com/info-room/products-innovations/expedry?ref=tmd-com">more info >></a></p>
  </>)
}

function UltraWht(){
  return(<>
    <p>UltraWht<br/>Pure White Down</p>
    <p>As a natural material, even the whitest down in the world can contain dark spots.</p>
    
    <p>With a conservative estimation of around 1000 down clusters per gram, even down sourced and traditionally separated to 99.9% white, still has the potential to contain at least 1 dark spot per gram.</p>
    
    <p>In a lightweight jacket, this could mean as many as 150 dark spots. As textiles push the limits of light weight, these dark spots are becoming increasingly more visually prevalent for some brands and products.</p>
    
    <p>ALLIED has worked with innovative machinists to develop a computerized sorting process that mechanically separates down cluster by cluster, picking out any dark spots. What is left is a pure white down with virtually no dark spots.</p>
    
    <p>All UltraWHT down is processed in the same way as all ALLIED down in our proprietary DuraWash process and sourced from our certified farms.</p>
    <p><a href="//alliedfeather.com/info-room/products-innovations/ultrawht?ref=tmd-com">more info >></a></p>
  </>)
}

function UltraWhtFR(){
  return(<>
    <p>UltraWht<br/>Duvet blanc pur</p>
    <p>En tant que matériau naturel, même le duvet le plus blanc du monde peut contenir des taches sombres.</p>
    
    <p>Avec une estimation prudente d&#39;environ 1 000 grappes de duvet par gramme, même le duvet provenant de sources traditionnellement séparées et blanches à 99,9 % peut contenir au moins une tache sombre par gramme.</p>
    
    <p>Dans une veste légère, cela peut représenter jusqu&#39;à 150 taches sombres. À mesure que les textiles repoussent les limites de la légèreté, ces taches sombres deviennent de plus en plus visibles pour certaines marques et certains produits.</p>
    
    <p>ALLIED a collaboré avec des machinistes innovants pour mettre au point un processus de tri informatisé qui sépare mécaniquement le duvet, grappe par grappe, en éliminant toutes les taches sombres. Il en résulte un duvet d&#39;un blanc pur, pratiquement dépourvu de taches sombres.</p>
    
    <p>Tous les duvets UltraWHT sont traités de la même manière que tous les duvets ALLIED, selon notre procédé exclusif DuraWash, et proviennent de nos fermes certifiées.</p>
    <p><a href="//alliedfeather.com/info-room/products-innovations/ultrawht?ref=tmd-com">plus d&#39;informations >></a></p>
  </>)
}

function Therma(){
  return(<>
    <p>ThermaDown O2+<br/>Infrared Active Down</p>
    <p>Most insulations can be greatly affected by sudden shifts in the external environment -- either overheating when the temperature rises or taking time to warm in sudden drops of temperature. The best insulation should act as a barrier to the environment, whether extreme cold or sudden warmth. “Active” insulations have recently become highly sought after, but most “active” insulations on the market do not perform based on the conditions of the exertion and environment, but are technically “static” insulations meant for active experiences.</p>
    <p>Adding infrared reflectivity helps create an insulation that is actively changing in tune with both the wearer and the environment. In our newly developed ThermaDown 02+,  as we expend energy from our bodies, it is reflected and returned; and as the external environment changes, excess energy can be reflected to keep from overheating quickly.</p>
    <p>Down already has a significantly wider comfort range than the alternatives. ThermaDown utilizes this range of comfort and down’s ability to warm with minimal weight and combines it with a patent pending technology that coats the down with tiny reflective platelets to provide a full-spectrum infrared reflectivity.</p>
    <p>We are essentially combining the efficiency of natural down with the benefits of a space blanket.</p>
    <p>As heat from the body is reflected off the platelets, it is converted into energy in an infrared spectrum. This energy interacts with our vascular system and helps us feel warmer and brings more oxygen to our muscles through increased microcirculation. This is why metallic space blankets are so effective for trauma situations. Infrared blankets are also used to help increase circulation in diabetic patients.</p>
    <p>ThermaDown allows for greater warmth with less weight, increased thermoregulation, and faster recovery through the reflection of energy in this infrared spectrum, creating a truly active insulation that works as hard as you do.</p>
    <p><a href="//alliedfeather.com/info-room/products-innovations/thermadown-o2-plus?ref=tmd-com">more info >></a></p>
  </>)
}

function ThermaFR(){
  return(<>
    <p>ThermaDown O2+<br/>Infrared Active Down</p>
    <p>La plupart des isolants peuvent être fortement affectés par des changements soudains dans l&#39;environnement extérieur, soit en surchauffant lorsque la température augmente, soit en mettant du temps à se réchauffer en cas de chute soudaine de la température. La meilleure isolation doit agir comme une barrière à l&#39;environnement, qu&#39;il s&#39;agisse de froid extrême ou de chaleur soudaine. Les isolants « actifs » sont récemment devenus très recherchés, mais la plupart des isolants « actifs » sur le marché ne fonctionnent pas en fonction des conditions de l&#39;effort et de l&#39;environnement, mais sont techniquement des isolants « statiques » destinés à des expériences actives.</p>
    <p>L&#39;ajout d&#39;une réflectivité infrarouge permet de créer une isolation qui évolue activement en fonction de l&#39;utilisateur et de l&#39;environnement. Dans notre ThermaDown 02+ nouvellement développé, lorsque nous dépensons de l&#39;énergie de notre corps, celle-ci est réfléchie et renvoyée ; et lorsque l&#39;environnement extérieur change, l&#39;excès d&#39;énergie peut être réfléchi pour éviter une surchauffe rapide.</p>
    <p>Le duvet offre déjà une gamme de confort beaucoup plus large que les autres matériaux. ThermaDown utilise cette gamme de confort et la capacité du duvet à réchauffer avec un poids minimal et l&#39;associe à une technologie en instance de brevet qui enrobe le duvet de minuscules plaquettes réfléchissantes pour fournir une réflectivité infrarouge à spectre complet.</p>
    <p>Nous combinons essentiellement l&#39;efficacité du duvet naturel avec les avantages d&#39;une couverture spatiale.</p>
    <p>Lorsque la chaleur du corps est réfléchie par les plaquettes, elle est convertie en énergie dans un spectre infrarouge. Cette énergie interagit avec notre système vasculaire, nous aide à nous sentir plus chaud et apporte plus d&#39;oxygène à nos muscles grâce à une microcirculation accrue. C&#39;est pourquoi les couvertures métalliques sont si efficaces dans les situations de traumatisme. Les couvertures infrarouges sont également utilisées pour améliorer la circulation chez les patients diabétiques.</p>
    <p>ThermaDown02+ permet d&#39;obtenir plus de chaleur avec moins de poids, une meilleure thermorégulation et une récupération plus rapide grâce à la réflexion de l&#39;énergie dans ce spectre infrarouge, créant ainsi une isolation véritablement active qui travaille aussi dur que vous.</p> 
    <p><a href="//alliedfeather.com/info-room/products-innovations/thermadown-o2-plus?ref=tmd-com">plus d&#39;informations >></a></p>
  </>)
}

function SpecificTreatment(props){
  //console.log(props)  
  
  //switch
  switch(props.feature){	  
	  case "HyperDry Eco":
	  switch(props.activeLang){
	    case "fr":	    
	    return <HyperDryFR />	    
	    default:
	    return <HyperDry/>
	  }
    
      case "HyperDRY Eco":
      switch(props.activeLang){
      case "fr":	    
      return <HyperDryFR />	    
      default:
      return <HyperDry/>
      }
	  
      case "HyperDRY":
      switch(props.activeLang){
        case "fr":	    
        return <HyperDryFR />	    
        default:
        return <HyperDry/>
      }
      
	  case "HyperDry":
	  switch(props.activeLang){
	    case "fr":	    
	    return <HyperDryFR />	    
	    default:
	    return <HyperDry/>
	  }
	  
	  case "ExpeDry":
      switch(props.activeLang){
      case "fr":	    
      return <ExpeDryFR />	 
      default:
      return <ExpeDry />	 
      }
      
      case "ExpeDRY":
      switch(props.activeLang){
      case "fr":	    
      return <ExpeDryFR />	 
      default:
      return <ExpeDry />	 
      }
      
      case "UltraWht":
	  switch(props.activeLang){
	    case "fr":	    
	    return <UltraWhtFR />	 
	    default:
	    return <UltraWht />	 
	  }
    
      case "ThermaDown O2+":
      switch(props.activeLang){
        case "fr":	    
        return <ThermaFR />	 
        default:
        return <Therma />	 
      }
      
	  
	  
	  default:
	  return <>SpecificTreatment Undefined</>
  }
  
}


const Treatment = (props) => {   	
  let cleanTitle;
  switch(props.activeLang){
    case "fr":	    	    
    cleanTitle = "TRAITEMENT";
	break;
	default:
	cleanTitle = "TREATMENT"; 	
	break;
  } 	  
  if(props.apply[0]){		
    return (<><div className="content-ac tr hide" data-feature={props.apply[0]}>
    <input
      className="ac-input top"
      id="ac-8afd"
      name="ac-8afd"
      data-id="2400"      
      data-slug="TREATMENT"
      type="checkbox"
      onChange={ () => props.changeWord('treatment clicked') }
    />
    <label className="ac-label" htmlFor="ac-8afd">{ props.apply[0] } { cleanTitle }</label>
    <article className="ac-text ac-8afd">
      <div className="single-post-interior" tabIndex="0">
	    <SpecificTreatment		
	      activeLang = {props.activeLang}    
	      feature = { props.apply[0] } 
	    />
	    <footer></footer>
	  </div>
    </article>
	</div></>);
  }else{
    return(<></>)
  }
}

export default Treatment;