import React from 'react';

function CareContent(){
return(<><p>With proper care, your down product can–and should–last a lifetime. One of the benefits of down is its ability to retain loft and resiliency year after year, even with heavy use. When synthetic options start to degrade within a couple seasons, your down jacket can last for many&nbsp;years.</p>
<p>Keeping your down product lofty, comfortable and warm for years requires relatively simple consideration and care.</p>
<p>Harsh detergents can strip the down of the fat and oil necessary to keep it resilient, causing it to become brittle so it is critical to use a mild detergent. ALLIED has developed a down wash that targets the very specific needs for keeping your down products clean.</p>
<p>We always recommend against dry cleaning. However, if the garment suggests dry clean only, take to a launderer that has experience cleaning down products.</p>
<br /><br />
<p>PRO TIP:</p>
<p>That new down jacket has been through a lot and has probably been packed for a while during shipping. Putting it through a wash cycle without detergent followed by a thorough dry as soon as you get home will help boost it back to maximum loft right away.</p></>)
}

function CareContentFR(){
return(<><p>Avec un entretien approprié, votre produit en duvet peut - et devrait - durer toute une vie. L&#39;un des avantages du duvet est sa capacité à conserver son gonflant et sa résilience année après année, même en cas d&#39;utilisation intensive. Alors que les produits synthétiques commencent à se dégrader au bout de quelques saisons, votre veste en duvet peut durer de nombreuses années.</p>
<p>Pour que votre produit en duvet reste gonflant, confortable et chaud pendant des années, il suffit d'une attention et d'un entretien relativement simples.</p>
<p>Les détergents puissants peuvent priver le duvet de la graisse et de l&#39;huile nécessaires à son élasticité et le rendre cassant. ALLIED a mis au point un produit de lavage du duvet qui répond aux besoins très spécifiques de la propreté de vos produits en duvet.</p>
<p>Nous déconseillons toujours le nettoyage à sec. Toutefois, si le vêtement suggère un nettoyage à sec uniquement, confiez-le à un blanchisseur expérimenté dans le nettoyage des produits à base de duvet.</p>
<br /><br />
<p>CONSEIL DE PRO:</p>
<p>Cette nouvelle doudoune a beaucoup souffert et a probablement été emballée pendant un certain temps lors du transport. Un lavage sans détergent suivi d&#39;un séchage complet dès votre retour à la maison vous permettra de lui redonner immédiatement son gonflant maximum.</p></>)
}


function SpecificCare(props){  
  switch(props.activeLang){
    case "fr":	    
    return <CareContentFR />;
    default:
    return <CareContent />;
  }
}

const Care = (props) => {   
	let cleanTitle;
	
	switch(props.activeLang){
    case "fr":	    
    cleanTitle = "SOINS";
    break;
    default:
    cleanTitle = "DOWN CARE";
    break;
    }
  
    return (<div className="content-ac ca hide">
    <input
      className="ac-input top"
      id="ac-6afd"
      name="ac-6afd"
      data-id="2400"
      data-slug="CARE"
      type="checkbox"
      onChange={ () => props.changeWord('care clicked') }
    />
    <label className="ac-label" htmlFor="ac-6afd">{ cleanTitle }</label>
    <article className="ac-text ac-6afd">
      <div className="single-post-interior" tabIndex="0">
      <SpecificCare
        activeLang = {props.activeLang}
      />
      </div>
    </article>
  </div>
);    
}

export default Care;