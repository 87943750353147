import React, { Component } from 'react';

export default class SearchForm extends Component {
  
  state = {
    activeLang: this.props.activeLang,
    searchText: '',   
    searchValid: this.props.searchValid
  }
  
  onSearchChange = e => {		
	this.props.searchDraft(e.target.value);
	
    this.setState({	  
	  searchText: e.target.value 
	});   	
  }
  
  searchHelp = e =>{
	e.preventDefault();    
    this.props.onHelp('',this.state.searchText);    
  }
  
  searchPlaceholder = e => {
    //activeLang??
    switch(this.props.activeLang){
	  case "fr":
	  return "Numéro de Lot N°";  
	  default:
	  return "Product Down Lot Nº";  
    }
  }
  
  handleSubmit = e => {	
    e.preventDefault();    
    if(e.target.childNodes[1].value.length >= 7){	    
	  this.props.searchValid(true);
	  // style input value	  	  
	  //e.target.childNodes[1].value = "LOADING LOT INFO...";
	}else{
	  console.log('handleSubmit: ELSE');
	  this.props.searchValid(false);
	  //style input
	  this.setState(prevState => ({
	    searchValid: false
	  }));	  
	  //this.props.searchFlag('',false);		  	  	  
	  e.target.childNodes[1].value = "INVALID LOT Nº";
	  
	  setTimeout(function(){		
		e.target.childNodes[1].value = "";
	    e.target.childNodes[1].placeholder = "Enter Lot Number"
	  },1500)
	}  
	
    //set windows searchParameter 
    const url = new URL(window.location);
	url.searchParams.set('lot', this.state.searchText.toUpperCase());
	window.history.pushState({}, '', url);
	
	let tmp = this.state.searchText;
	let str = tmp.replace(/[^a-z0-9áéíóúñü,_-]/gim, " ");
    str = str.trim();

	
    this.props.onSearch('',str);
    //e.currentTarget.reset();
  }
    
  render() {  			
    return (
      <form className="search-form" onSubmit={this.handleSubmit} >
        <label className="is-hidden" htmlFor="search">Search</label>        
        <input type="search" 
          onChange={ this.onSearchChange }
          name="search" 
          placeholder= {this.searchPlaceholder().toString()}
          tabIndex="0"
        />
        <button type="submit" id="submit" className="search-button"><i className="material-icons icn-search">search</i></button>
	    <button type="info" id="help" 
	      title="Get Help Tracking Your Down Product"
	      onClick={ this.searchHelp }
	      tabIndex="0"
	    >&#9432;</button> 
      </form>      
    );
  }
}