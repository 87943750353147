import React from 'react';

function originRegion(z){  	
  if(z.includes("HU")){
    if(z.includes('WGD')){
	  return( <ul>
	  <li>Eastern Hungary (Hajdusamson/Debrecen)</li>
	  <li>Southern Hungary (Melykut)</li>
	  <li>South-East Hungary (Szentes)</li></ul>);	  
    }
    
    if(z.includes('GGD')){
	  return <ul><li>Southern Hungary</li></ul>;	  
    }
    if(z.includes('WDD')){
	  return( <ul>
	  <li>Eastern Hungary (Hajdusamson/Debrecen)</li>
	  <li>Southern Hungary (Melykut)</li></ul>);
    }
    if(z.includes('GDD')){
	  return(<ul><li>Southern Hungary (Melykut)</li></ul>);
    }
  }
 
  if(z.includes("EU")){
    if(z.includes('WGD')){
	  return( <ul><li>Hungary</li>
	  <li>Eastern Hungary (Hajdusamson/Debrecen)</li>
	  <li>Southern Hungary (Melykut)</li>
	  <li>South-East Hungary (Szentes)</li></ul>);
    }
    
    if(z.includes('GGD')){	    
	  return <ul><li>Southern Hungary</li></ul>;	  
    }
    
    if(z.includes('WDD')){	  	  	  	  
	  return( <ul><li>Eastern + Southern Hungary</li>
	  <li>Eastern Hungary (Hajdusamson/Debrecen)</li>
	  <li>Southern Hungary (Melykut)</li>
	  <li>South-East Hungary (Szentes)</li></ul>);	  
    }
    
    if(z.includes('GDD')){
	  return( <ul>
	  <li>Southern Hungary, Romania</li>
	  <li>Southern Hungary</li>
	  <li>Suceava, Botosani, Iasi, Neamti, Bacau, Vaslui, Vrancea, Romania</li></ul>);
    }
  }
  
  if(z.includes("CN")){
	if(z.includes('WGD')){
	  return( <ul>
	  <li>Shanxi Province</li>
	  <li>Anhui Province</li>
	  <li>Jiangsu Province</li>
	  <li>Shuyang Province</li></ul>); 	
	}  
	if(z.includes('GGD')){
	  return( <ul><li>Guangxi Province, Qingyuan Province, Shangdong Province</li>
	  <li>Guangxi Province (Nanning City)</li>
	  <li>Guangxi Province (Guigang City)</li></ul>); 
	}	
	if(z.includes('WDD')){
	  return( <ul><li>Shangdong Province, Anhui Province</li></ul>);	
	}
	if(z.includes('GDD')){
	  return( <ul>
	  <li>Guangxi Province, Fuzhou Province, Jiangxi Province</li>
	  <li>Guangxi Province (Nanning City)</li>
	  <li>Guangxi Province (Guigang City)</li></ul>);
	}    
  }    
}

function SpecificInterior(props){
  let species 		  = props.lotSpecies;   
  let regionalOrigin  = <OriginDesc alt='0'/>;		
  
  switch(props.lotSourcing){	 
	  case 'WGD_EU':	    
	    if(species.includes('Renu')){		  		  
		  regionalOrigin = <OriginDesc region="eu" color="w" species="renu" alt='1' lang={props.activeLang} />	    
		}else{
		  switch(props.activeLang){
		    case "fr":
		      regionalOrigin = <OriginDescFR region="eu" color="w" species="g" alt='1' lang={props.activeLang} />
		    break;
		    default:
		      regionalOrigin = <OriginDesc region="eu" color="w" species="g" alt='1' />
		    break;
		  }		  
	    }
	  break;
	  case 'GGD_EU':	 	    
	    //EU	    
		if(species.includes('Renu')){
		  regionalOrigin = <OriginDesc region="eu" color="g" species="renu" alt='1'/>
		}else{		  
		  switch(props.activeLang){
		  case "fr":
		  regionalOrigin = <OriginDescFR region="eu" color="g" species="g" alt='1' lang={props.activeLang}/>
		  break;
		  default:
		  regionalOrigin = <OriginDesc region="eu" color="g" species="g" alt='1'/>    
		  break;
		  }
	    }
	  break;	  
	  
	  case 'WGD_CN':	 	    
	    switch(props.activeLang){
		  case "fr":
		  regionalOrigin = <OriginDescFR region="cn" color="w" species="g" lang={props.activeLang}/>
		  break;
		  default:
		  regionalOrigin = <OriginDesc region="cn" color="w" species="g"/>
		  break;
		}
	  break;	  
	  
	  case 'GGD_CN':	 	    
	    switch(props.activeLang){
		  case "fr":
		  regionalOrigin = <OriginDescFR region="cn" color="g" species="g" lang={props.activeLang}/>
		  break;
		  default:
		  regionalOrigin = <OriginDesc region="cn" color="g" species="g"/>
		  break;
		}
	  break;
	  
	  case 'WDD_EU':	    
	    if(species.includes('Renu')){
		  regionalOrigin = <OriginDesc region="eu" color="w" species="d" alt='1'/>	    
		}else{		  
		  switch(props.activeLang){
		    case "fr":
		      regionalOrigin = <OriginDescFR region="eu" color="w" species="d" alt='1' lang={props.activeLang}/>
		    break;
		    default:
		      regionalOrigin = <OriginDesc region="eu" color="w" species="d" alt='1'/>
		    break;
		  }
	    }
	  break;	  
	  case 'WDD_CN':	    
	    if(species.includes('Renu')){
		  regionalOrigin = <OriginDesc region="cn" color="w" species="d"/>	    
		}else{
		  switch(props.activeLang){
		    case "fr":
		      regionalOrigin = <OriginDescFR region="cn" color="w" species="d" lang={props.activeLang}/>
		    break;
		    default:
		      regionalOrigin = <OriginDesc region="cn" color="w" species="d" />
		    break;
		  }			  
	    }
	  break;
	  
	  case 'GDD_EU':	 
	    switch(props.activeLang){
		  case "fr":
		  regionalOrigin = <OriginDescFR region="eu" color="g" species="d" alt='1' lang={props.activeLang}/>
		  break;
		  default:
		  regionalOrigin = <OriginDesc region="eu" color="g" species="d" alt='1'/>
		  break;
	    }	
	  break;
	  
	  case 'GDD_CN':	 	    
	    switch(props.activeLang){
		  case "fr":
		  regionalOrigin = <OriginDescFR region="cn" color="g" species="d" lang={props.activeLang}/>
		  break;
		  default:
		  regionalOrigin = <OriginDesc region="cn" color="g" species="d"/>
		  break;
	    }
	  break;
	  
	  //hungary
	  case 'WGD_HU':	    
	    if(species.includes('Renu')){		  		  
		  regionalOrigin = <OriginDesc region="hu" color="w" species="renu" alt='2' lang={props.activeLang} />	    
		}else{
		  switch(props.activeLang){
		    case "fr":
		      regionalOrigin = <OriginDescFR region="hu" color="w" species="g" alt='2' lang={props.activeLang} />
		    break;
		    default:
		      regionalOrigin = <OriginDesc region="hu" color="w" species="g" alt='2' />
		    break;
		  }		  
	    }
	  break;
	  
	  case 'GGD_HU':	 	    
		if(species.includes('Renu')){
		  regionalOrigin = <OriginDesc region="hu" color="g" species="renu" alt='2'/>
		}else{		  
		  switch(props.activeLang){
		  case "fr":
		  regionalOrigin = <OriginDescFR region="hu" color="g" species="g" alt='2' lang={props.activeLang}/>
		  break;
		  default:
		  regionalOrigin = <OriginDesc region="hu" color="g" species="g" alt='2' />    
		  break;
		  }
	    }
	  break;	 
	  
	  case 'WDD_HU':	    
	    if(species.includes('Renu')){
		  regionalOrigin = <OriginDesc region="hu" color="w" species="d" alt='2'/>	    
		}else{		  
		  switch(props.activeLang){
		    case "fr":
		      regionalOrigin = <OriginDescFR region="hu" color="w" species="d" alt='2' lang={props.activeLang}/>
		    break;
		    default:
		      regionalOrigin = <OriginDesc region="hu" color="w" species="d" alt='2'/>
		    break;
		  }
	    }
	  break;	
	  
	  case 'GDD_HU':	 
	    switch(props.activeLang){
		  case "fr":
		  regionalOrigin = <OriginDescFR region="hu" color="g" species="d" alt='2' lang={props.activeLang}/>
		  break;
		  default:
		  regionalOrigin = <OriginDesc region="hu" color="g" species="d" alt='2'/>
		  break;
	    }	
	  break;
	  
	  default:	   
	    //CN
	    if(species.includes('Renu')){
		  regionalOrigin = <OriginDesc species="renu"/>	    
	    }else{		  
		  switch(props.activeLang){
		  case "fr":
		  regionalOrigin = <OriginDescFR region="cn" color="w" species="d" lang={props.activeLang}/>
		  break;
		  default:
		  regionalOrigin = <OriginDesc region="cn" color="w" species="d"/>
		  break;
		  }
	    }	    
	  break;
	}
  return(regionalOrigin);
}

function origInterior(species, origin, sourcing, activeLang){	
	//conditional for the inner component
	let regionalOrigin  = <OriginDesc alt='0'/>;	
    //console.log('!! :'+origin)
	//Origin Description
	switch(sourcing){
	  case 2:
	    //HUNGARY
	    regionalOrigin = <OriginDesc alt='2'/>
	  break;
	  case 'WGD_EU':	    
	    if(species.includes('Renu')){		  		  
		  regionalOrigin = <OriginDesc region="eu" color="w" species="renu" alt='1' lang ={activeLang} />	    
		}else{
		  regionalOrigin = <OriginDesc region="eu" color="w" species="g" alt='1' lang={activeLang} />
	    }
	  break;
	  case 'GGD_EU':	 	    
	    //EU
		if(species.includes('Renu')){
		  regionalOrigin = <OriginDesc region="eu" color="g" species="renu" />
		}else{
		  regionalOrigin = <OriginDesc region="eu" color="g" species="g" />    
	    }
	  break;	  
	  case 'WGD_CN':	 
	    regionalOrigin = <OriginDesc region="cn" color="w" species="g"/>
	  break;	  
	  case 'GGD_CN':	 
	    regionalOrigin = <OriginDesc region="cn" color="g" species="g"/>
	  break;
	  
	  case 'WDD_EU':	    
	    if(species.includes('Renu')){
		  regionalOrigin = <OriginDesc region="eu" color="w" species="d" alt='1'/>	    
		}else{
		  regionalOrigin = <OriginDesc region="eu" color="w" species="d" alt='1'/>
	    }
	  break;
	  
	  case 'WDD_CN':	    
	    if(species.includes('Renu')){
		  regionalOrigin = <OriginDesc region="cn" color="w" species="d" alt='1'/>	    
		}else{
		  regionalOrigin = <OriginDesc region="cn" color="w" species="d" alt='1'/>
	    }
	  break;
	  
	  case 'GDD_EU':	 
	    regionalOrigin = <OriginDesc region="eu" color="g" species="d"/>
	  break;
	  
	  case 'GDD_CN':	 
	    regionalOrigin = <OriginDesc region="cn" color="g" species="d"/>
	  break;
	  default:	   
	    //CN
	    if(species.includes('Renu')){
		  regionalOrigin = <OriginDesc species="renu"/>	    
	    }else{
		  regionalOrigin = <OriginDesc region="cn" color="w" species="d"/>	    
	    }	    
	  break;
	}
	return regionalOrigin;
}

class OriginDesc extends React.Component{
	constructor(props){
	  super(props);
	  this.lang       = this.props.lang;
	  this.region     = this.props.region;	  
	  this.color      = this.props.color;	  
	  this.alt        = this.props.alt;	  
	  this.species    = this.props.species;	  
	  
	  //RENU
	  this.RenuDesc = (<><p>Virgin down remains an important part of the circular economy, and one of the most sustainable insulations on the planet and is naturally biodegradable. But in looking at ways we can be more proactive in our environmental stewardship practices with our partners, textile waste was one area we felt we could expand upon and build circularity for all our partners.</p><p>By working with global recycling centers and developing our own recycling programs, ALLIED now takes old bedding products, apparel and sleeping bags – otherwise destined for the landfill – and is able to reuse the material inside while sending the textiles and trims into their own separate repurposing and recycling streams.</p><p>The removed down is cleaned through our DURWASH process the same as conventional material and undergoes rigorous separation processes to create the highest performing recycled down available today.</p><p>ALLIED’s RENU:Performance Recycled Down is an insulation with a positive impact on the environment with ALLIED helping to recycle over 1 million pounds of textile waste annually. That’s equivalent to taking over 260 vehicles off the road every year. </p></>)
	  
	  //WGD
	  this.WGD_HU = (<p>The White Goose Down we sourced for this lot comes solely from the Southern, Eastern and South Eastern regions of Hungary. Hungary is known to produce some of the finest down in the world. This is due to a combination of a particular sub-species and the climate where these birds are raised. Living longer and in colder climates than White Geese found in other parts of the world, these birds produce extremely high quality down. All of our Hungarian sources are certified to the Responsible Down Standard to ensure that these farms are rigorously&nbsp;audited.</p>);
	  
	  this.WGD_EU = (<p>The European White Goose Down we sourced for this lot comes from the Southern, Eastern and South Eastern regions in Hungary. In most cases, the final lot of down is blended from various sources - much like wine - to build an insulation with the exact properties needed for this product. These regions tend to be much colder than others and the birds live slightly longer producing an extremely high quality, luxurious down. These regions are also known for a particular sub-species that provides an extremely white feather and down. Since ALLIED does not use any bleaches when processing, it is important that our White Goose Down comes from such sources.</p>);
	  
	  this.WGD_EUFR = (<p>Le duvet d&#39;oie blanc européen que nous avons utilisé pour ce lot provient des régions du sud, de l'est et du sud-est de la Hongrie. Dans la plupart des cas, le lot final de duvet est mélangé à partir de différentes sources - un peu comme le vin - afin de construire un isolant ayant les propriétés exactes requises pour ce produit. Ces régions ont tendance à être beaucoup plus froides que les autres et les oiseaux vivent un peu plus longtemps, ce qui donne un duvet luxueux et de très haute qualité. Ces régions sont également connues pour une sous-espèce particulière qui produit un duvet et des plumes extrêmement blancs. Comme ALLIED n'utilise aucun agent de blanchiment lors du traitement, il est important que notre duvet d&#39;oie blanc provienne de telles sources.</p>);
	  
	  this.WGD_CN = (<p>ALLIED’S Chinese White Goose Down mainly comes from the provinces of Shanxi, Anhui, Jiangsu and Shuyang provinces. White Goose Down at a higher fill power can be a fairly high risk for live plucking - though that only occurs within a small part of the supply chain. Even though live plucking is rare, we are still very careful within this supply chain and have identified those regions having the best transparency through parent farms to ensure that no material ALLIED procures comes from birds that have been live-plucked at any time in their lives.</p>)
	  
	  this.WGD_CNFR = (<p>Le duvet d&#39;oie blanc chinois d&#39;ALLIED&#39;S provient principalement des provinces de Shanxi, Anhui, Jiangsu et Shuyang. Le duvet d&#39;oie blanche à haut pouvoir de remplissage peut présenter un risque assez élevé de plumage vivant - bien que cela ne se produise que dans une petite partie de la chaîne d&#39;approvisionnement. Même si la plumaison à vif est rare, nous restons très prudents au sein de cette chaîne d&#39;approvisionnement et avons identifié les régions présentant la meilleure transparence par le biais des fermes mères afin de garantir qu&#39;aucune matière achetée par ALLIED ne provient d&#39;oiseaux qui ont été plumés à vif à un moment quelconque de leur&nbsp;vie.</p>)	  
	  
	  //GGD
	  this.GGD_HU = (<p>The Grey Goose Down we sourced for this lot comes solely from Southern Hungary. Hungary is known to produce some of the finest down in the world. This is due to a combination of the particular sub-species and climate where these birds are raised. Living longer and in colder climates than Grey Geese found in other parts of the world, these birds produce extremely high quality down. All of our Hungarian sources are certified to the Responsible Down Standard to ensure that these farms are rigorously audited.</p>);
	  
	  this.GGD_EU = (<p>The European Grey Goose Down we sourced for this lot comes from Southern Hungary and Romania. The nature of the Grey Goose supply chain is such that most of this material comes from smaller collector-based farms. These birds tend to be much older than those found in an industrial supply chain and therefore provide extremely high quality down.</p>);
	  
	  this.GGD_CN = (<p>The Chinese Grey Goose Down mainly comes from the provinces of Guanxi, Qingyuan and Shandong. There is not a tremendous quantity of Grey Goose in China as much of the poultry raised for food is either White Goose or Duck.</p>);
	  
	  //WDD
	  this.WDD_EU = (<p>The European White Duck Down we sourced for this lot comes predominantly from Southern, Eastern and South Eastern Hungary. In most cases, the final lot of down is blended from various sources - much like wine - to build an insulation with the exact properties needed for this product. ALLIED is very particular about where our European duck down is sourced due to the larger Foie Gras industry. ALLIED does not source down from any farm engaged in the practice of force feeding for this industry, which is why you will rarely see ALLIED down coming from countries like France.</p>);
	  
	  this.WDD_CN = (<p>Chinese White Duck Down is probably the most common down item in the world. We work with farms in Shandong and Anhui to not only ensure the animal welfare and traceability, but overall chemical management and environmental impact. It makes no sense to us at ALLIED to source what could effectively be the most sustainable insulation on the planet from farms engaged in harmful environmental practices.</p>)
	  
	  //GDD
	  this.GDD_EU = (<p>The European Grey Duck Down we sourced for this lot comes predominantly from Southern Hungary and Romania. In most cases, the final lot of down is blended from various sources - much like wine - to build an insulation with the exact properties needed for this product. ALLIED is very particular about where our European duck down is sourced due to the larger Foie Gras industry in this region. ALLIED does not source down from any farm engaged in the practice of force feeding for this industry, which is why you will rarely see ALLIED down coming from countries like France.</p>);	  
	  
	  this.GDD_CN = (<p>ALLIED’s Chinese Grey Duck is mainly sourced from the Guangxi, Fuzhou and Jiangxi provinces.  We have also uncovered Grey Duck supply chains in the northernmost parts of China that can achieve high fill powers previously only thought possible with Geese. It is an extremely high quality down that can be used in the most technical and warmest products.</p>);	  
	  	 	  
	}	
			
    render(props){	    	    
	    if(this.species == 'renu'){
		 //renu   
		 return(this.RenuDesc);
	    }else if(this.alt == 2){
		  //hungary
		  if(this.color == 'w'){
		    if(this.species == 'g'){
		      return(this.WGD_HU);
		    }
			//we dont source HU Duck		  
		  }else{
			//
		    return(this.GGD_HU);	  
		  }		  
		}else if(this.alt == 1 && this.region == "eu" ){
			if(this.color == 'w'){
				if(this.species == 'g'){
				  return(this.WGD_EU);				  
				}else{
				  return(this.WDD_EU);	
				}
		    }else{
			  if(this.species == 'g'){
		        return(this.GGD_EU);	  
		      }else{
			    return(this.GDD_EU);	    
		      }
		    }		  
		}else{			
		  //CN
		  if(this.color == 'w'){
			if(this.species == "renu"){
			  return(this.RenuDesc);  
			}else if(this.species == 'g'){
		      return(this.WGD_CN);
			}else{
			  return(this.WDD_CN);   			   
			}		    
		  }else{
		    //Grey
		    if(this.species == 'g'){
			  return(this.GGD_CN);
		    }else{
			  return(this.GDD_CN);
		    }
		  }		  
		}
	}		   
}

class OriginDescFR extends React.Component{
	constructor(props){
	  super(props);
	  this.lang       = this.props.lang;
	  this.region     = this.props.region;	  
	  this.color      = this.props.color;	  
	  this.alt        = this.props.alt;	  
	  this.species    = this.props.species;	  
	  
	  //RENU
	  this.RenuDesc = (<><p>Virgin down remains an important part of the circular economy, and one of the most sustainable insulations on the planet and is naturally biodegradable. But in looking at ways we can be more proactive in our environmental stewardship practices with our partners, textile waste was one area we felt we could expand upon and build circularity for all our partners.</p><p>By working with global recycling centers and developing our own recycling programs, ALLIED now takes old bedding products, apparel and sleeping bags – otherwise destined for the landfill – and is able to reuse the material inside while sending the textiles and trims into their own separate repurposing and recycling streams.</p><p>The removed down is cleaned through our DURWASH process the same as conventional material and undergoes rigorous separation processes to create the highest performing recycled down available today.</p><p>ALLIED’s RENU:Performance Recycled Down is an insulation with a positive impact on the environment with ALLIED helping to recycle over 1 million pounds of textile waste annually. That’s equivalent to taking over 260 vehicles off the road every year. </p></>)
	  
	  //WGD
	  this.WGD_HU = (<p>Le duvet d&#39;oie blanc que nous avons sélectionné pour ce lot provient uniquement des régions du sud, de l&#39;est et du sud-est de la Hongrie. La Hongrie est connue pour produire l&#39;un des duvets les plus fins au monde. Cela est dû à la combinaison d&#39;une sous-espèce particulière et du climat dans lequel ces oiseaux sont élevés. Vivant plus longtemps et dans des climats plus froids que les oies blanches que l&#39;on trouve dans d&#39;autres parties du monde, ces oiseaux produisent un duvet de très haute qualité. Toutes nos sources hongroises sont certifiées conformes à la norme Responsible Down Standard afin de garantir que ces exploitations font l&#39;objet d&#39;un audit&nbsp;rigoureux.</p>);
	  	  
	  this.WGD_EU = (<p>Le duvet d&#39;oie blanc européen que nous avons utilisé pour ce lot provient des régions du sud, de l&#39;est et du sud-est de la Hongrie, ainsi que du centre de la Pologne. Dans la plupart des cas, le lot final de duvet est mélangé à partir de différentes sources - un peu comme le vin - afin de construire un isolant ayant les propriétés exactes requises pour ce produit. Ces régions ont tendance à être beaucoup plus froides que les autres et les oiseaux vivent un peu plus longtemps, ce qui donne un duvet luxueux et de très haute qualité. Ces régions sont également connues pour une sous-espèce particulière qui produit un duvet et des plumes extrêmement blancs. Comme ALLIED n&#39;utilise aucun agent de blanchiment lors du traitement, il est important que notre duvet d&#39;oie blanc provienne de telles&nbsp;sources.</p>);
	  
	  this.WGD_CN = (<p>Le duvet d&#39;oie blanc chinois d&#39;ALLIED&#39;S provient principalement des provinces de Shanxi, Anhui, Jiangsu et Shuyang. Le duvet d&#39;oie blanche à haut pouvoir de remplissage peut présenter un risque assez élevé de plumage vivant - bien que cela ne se produise que dans une petite partie de la chaîne d&#39;approvisionnement. Même si la plumaison à vif est rare, nous restons très prudents au sein de cette chaîne d&#39;approvisionnement et avons identifié les régions présentant la meilleure transparence par le biais des fermes mères afin de garantir qu&#39;aucune matière achetée par ALLIED ne provient d&#39;oiseaux qui ont été plumés à vif à un moment quelconque de leur&nbsp;vie</p>)
	  
	  //GGD
	  this.GGD_HU = (<p>Le duvet Grey Goose que nous avons sélectionné pour ce lot provient uniquement du sud de la Hongrie. La Hongrie est connue pour produire l&#39;un des duvets les plus fins au monde. Cela est dû à la combinaison de la sous-espèce particulière et du climat où ces oiseaux sont élevés. Vivant plus longtemps et dans des climats plus froids que les oies grises que l&#39;on trouve dans d&#39;autres parties du monde, ces oiseaux produisent un duvet de très haute qualité. Toutes nos sources hongroises sont certifiées conformes à la norme Responsible Down Standard afin de garantir que ces exploitations sont soumises à un audit&nbsp;rigoureux.</p>);
	  
	  this.GGD_EU = (<p>Le duvet Grey Goose européen que nous avons acheté pour ce lot provient du sud de la Hongrie et de la Roumanie. La nature de la chaîne d&#39;approvisionnement de Grey Goose est telle que la plupart de ce matériel provient de petites fermes de collection. Ces oiseaux sont généralement beaucoup plus âgés que ceux que l&#39;on trouve dans une chaîne d&#39;approvisionnement industrielle et fournissent donc un duvet de très haute&nbsp;qualité.</p>);
	  
	  this.GGD_CN = (<p>Le duvet d&#39;oie grise chinois provient principalement des provinces de Guanxi, Qingyuan et Shandong. Il n&#39;y a pas une quantité énorme d&#39;oie grise en Chine, car la plupart des volailles élevées pour l&#39;alimentation sont des oies blanches ou des&nbsp;canards.</p>);
	  
	  //WDD
	  this.WDD_EU = (<p>Le duvet de canard blanc européen que nous avons utilisé pour ce lot provient principalement du sud, de l&#39;est et du sud-est de la Hongrie. Dans la plupart des cas, le lot final de duvet est mélangé à partir de différentes sources - un peu comme le vin - afin de construire un isolant ayant les propriétés exactes requises pour ce produit. ALLIED est très attentif à la provenance de son duvet de canard européen en raison de l&#39;importance de l&#39;industrie du foie gras. ALLIED ne s&#39;approvisionne pas en duvet auprès d&#39;une ferme pratiquant le gavage pour cette industrie, c&#39;est pourquoi vous verrez rarement du duvet ALLIED provenant de pays comme la France.</p>);
	  
	  this.WDD_CN = (<p>Le duvet de canard blanc chinois est probablement l&#39;article en duvet le plus répandu dans le monde. Nous travaillons avec des fermes de Shandong et d&#39;Anhui pour garantir non seulement le bien-être et la traçabilité des animaux, mais aussi la gestion globale des produits chimiques et l&#39;impact environnemental. Pour nous, chez ALLIED, cela n&#39;a aucun sens de s&#39;approvisionner en ce qui pourrait être l&#39;isolant le plus durable de la planète auprès de fermes ayant des pratiques environnementales&nbsp;néfastes.</p>)
	  
	  //GDD
	  this.GDD_EU = (<p>Le duvet de canard gris européen que nous avons utilisé pour ce lot provient principalement du sud de la Hongrie et de la Roumanie. Dans la plupart des cas, le lot final de duvet est mélangé à partir de différentes sources - un peu comme le vin - afin de construire un isolant ayant les propriétés exactes requises pour ce produit. ALLIED est très attentif à la provenance de son duvet de canard européen en raison de l&#39;importance de l&#39;industrie du foie gras dans cette région. ALLIED ne s&#39;approvisionne pas en duvet auprès d&#39;une ferme pratiquant le gavage pour cette industrie, c&#39;est pourquoi vous verrez rarement du duvet ALLIED provenant de pays comme la&nbsp;France.</p>);	  
	  
	  this.GDD_CN = (<p>Le canard gris chinois d&#39;ALLIED provient principalement des provinces de Guangxi, Fuzhou et Jiangxi.  Nous avons également découvert des chaînes d&#39;approvisionnement en canard gris dans les régions les plus septentrionales de la Chine, qui permettent d&#39;obtenir des pouvoirs de remplissage élevés que l&#39;on ne pensait auparavant possibles qu&#39;avec des oies. C&#39;est un duvet de très haute qualité qui peut être utilisé dans les produits les plus techniques et les plus&nbsp;chauds.</p>);	  
	  	 	  
	}	
			
    render(props){
	    if(this.species == 'renu'){
		 //renu   
		 return(this.RenuDesc);
	    }else if(this.alt == 2){
		  //hungary
		  if(this.color == 'w'){
		    if(this.species == 'g'){
		      return(this.WGD_HU);
		    }
			//we dont source HU Duck		  
		  }else{
			//
		    return(this.GGD_HU);	  
		  }		  
		}else if(this.alt == 1){
			if(this.color == 'w'){
				if(this.species == 'g'){
				  return(this.WGD_EU);				  
				}else{
				  return(this.WDD_EU);	
				}
		    }else{
			  if(this.species == 'g'){
		        return(this.GGD_EU);	  
		      }else{
			    return(this.GDD_EU);	    
		      }
		    }		  
		}else{			
		  //CN
		  if(this.color == 'w'){
			if(this.species == "renu"){
			  return(this.RenuDesc);  
			}else if(this.species == 'g'){
		      return(this.WGD_CN);
			}else{
			  return(this.WDD_CN);   			   
			}		    
		  }else{
		    //Grey
		    if(this.species == 'g'){
			  return(this.GGD_CN);
		    }else{
			  return(this.GDD_CN);
		    }
		  }		  
		}
	}		   
}

function Origin(props){
  let   lotOrigin    = props.lotOrigin;
  let   lotSpecies   = props.lotSpecies;
  let   activeLang   = props.activeLang; 
  let   lotSourcing  = 0;
  let   innerTitle   = "";
  
  //handle sourcing locations logic here
  
  if(lotSpecies.includes('Renu')){
    lotSourcing = "Renu";	  
  }else if(lotSpecies.includes('Goose')){	
	//return <Origin_EU alt="0" />;	   
    innerTitle = "Anser Anser f. Domestica"
    if(lotOrigin.includes('Hungary')){
	  if(lotSpecies.includes('White')){
		  lotSourcing = "WGD_HU";   
	  }else{
		  lotSourcing = "GGD_HU";   
	  }
    }else if(lotOrigin.includes('Europe')){
      if(lotSpecies.includes('White')){
	    //WGD  
	    lotSourcing = "WGD_EU";
	  }else{
		//GGD  
	    lotSourcing = "GGD_EU";
	  }
    }else{
	  //CN
	  if(lotSpecies.includes('White')){
	    //WGD  
	    lotSourcing = "WGD_CN";
	  }else{
		//GGD  
	    lotSourcing = "GGD_CN";
	  }
    }
  }else{
    //duck
    innerTitle = "Anas Bochas Domestica"
    if(lotOrigin.includes('Hungary')){
	  if(lotSpecies.includes('White')){
	    //WDD
		lotSourcing = "WDD_HU";   
	  }else{
	    //GDD
		lotSourcing = "GDD_HU";   
	  }
    }else if(lotOrigin.includes('Europe')){
      if(lotSpecies.includes('White')){
	    //WDD  
	    lotSourcing = "WDD_EU";
	  }else{
		//GDD  
	    lotSourcing = "GDD_EU";
	  }
    }else{
	  //CN
	  if(lotSpecies.includes('White')){
	    //WDD  
	    lotSourcing = "WDD_CN";
	  }else{
		//GDD  
	    lotSourcing = "GDD_CN";
	  }    
    }

  }
  
  let locationID;   
  
  switch(props.activeLang){
    case "fr":	    	    
    if(lotSpecies != "Renu Blend"){
    switch(lotOrigin){
	  case "Hungary":
	  locationID = "ORIGINE HONGROIS"
	  break;
	  case "Europe":
	  locationID =  "ORIGINE EUROPÉEN"	  	    
	  break;
	  default:
	  locationID =  "ORIGINE CHINOISE"
	  break;
	}
	}
    break;
    
    default:
    if(lotSpecies != "Renu Blend"){
    switch(lotOrigin){
	  case "Hungary":
	  locationID = "HUNGARIAN ORIGIN"
	  break;
	  case "Europe":
	  locationID =  "EUROPEAN ORIGIN"	  	    
	  break;
	  default:
	  locationID =  "CHINESE ORIGIN"
	  break;
	}
	}
  
    break;
  }

  
  //filter for RENU here??
  
  return <div className="content-ac or hide">
		 <input
		   className="ac-input top"
		   id="ac-2afd"
		   name="ac-2afd"
		   data-id="2400"
		   data-slug="ORIGIN"
		   type="checkbox"
		   onChange={ () => props.changeWord('or clicked') }
		 />
		 <label className="ac-label" htmlFor="ac-2afd">{ locationID }</label>
		 <article className="ac-text ac-2afd"><div className="single-post-interior" tabIndex="0">	
		   	 { lotSpecies != "Renu Blend" 
			   ? originRegion(lotSourcing) 
			   : null
			 }
			 {/* origInterior(lotSpecies, lotOrigin, lotSourcing, activeLang) */}
			 <SpecificInterior
		       activeLang  = { props.activeLang }		       
		       lotSpecies  = { props.lotSpecies }		       
		       lotOrigin   = { props.lotOrigin }
		       lotSourcing = { lotSourcing }		       
		     />
		 </div></article>
		</div>		

}


export default Origin;