import React from 'react';
  
const UpperNavList = (props) => {   
  let cleanADW, cleanOne, cleanDC, cleanRS, cleanP, cleanC, lotInquiry;
  switch(props.lang){
	case 'fr':
	  cleanADW = "ALLIED DOWN WASH";
	  cleanOne = "DUVET 101";
	  cleanDC  = "SOINS DU DUVET";
	  cleanRS  = "L'APPROVISIONNEMENT RESPONSABLE";	  
	  cleanP   = "PARTENAIRES";	   
	  cleanC   = "CONTACT";	   
	break;	
	default:
	  cleanADW = "ALLIED DOWN WASH";
	  cleanOne = "DOWN 101";
	  cleanDC  = "DOWN CARE";
	  cleanRS  = "RESPONSIBLE SOURCING";	  
	  cleanP   = "PARTNERS";	   
	  cleanC   = "CONTACT";
	break;
  }
   
  if(props.searchText !== ""){
    lotInquiry = props.searchText;
  }else{
	lotInquiry = props.searchText;  
  }
  
  if(props.lotError !== ""){
	lotInquiry = props.lotError;
  }
  
  return(<>    
    <li>
    <a href="https://alliedfeather.com/shop" target="_blank" rel="noopener noreferrer" tabIndex="0">{ cleanADW }</a>
    </li>    
    <li>
      <a href="https://alliedfeather.com/info-room/down-101/the-cluster" target="_blank" rel="noopener noreferrer" tabIndex="0">{ cleanOne }</a>
    </li>
    <li>
      <a href="https://alliedfeather.com/downcare" target="_blank" rel="noopener noreferrer" tabIndex="0">{ cleanDC }</a>
    </li>
    <li>
      <a href="https://alliedfeather.com/sourcing" target="_blank" rel="noopener noreferrer" tabIndex="0">{ cleanRS }</a>
    </li>
    <li>
      <a href="https://alliedfeather.com/partners" target="_blank" rel="noopener noreferrer" tabIndex="0">{ cleanP }</a>
    </li>
    <li>
      <a className="interior-contact" href={
	    props.searchText  ?
	    `mailto:help@trackmydown.com?subject=TMD Inquiry | lot `+ props.searchText
	    : `mailto:help@trackmydown.com?subject=TMD Inquiry`
	  } tabIndex="0">{ cleanC }</a>
    </li>	  	    
  </>)
}

export default UpperNavList;