import React from 'react';

function contentEN(){
  return(<><p>Down is a natural product that, like any other organic material, is susceptible to mold, dust mites, and other&nbsp;allergens.</p><p>However, as a keratin-based protein similar to our hair, very few people have an allergy to the down itself. Those reactions are more often the result of dust mites and other allergens present in unclean down. ALLIED’s premium down utilizes a unique washing procedure that uses significantly less water than other industry practices, recycles the water that is used and cleans the down thoroughly with environmentally friendly bluesign® approved detergents.</p><p>To quantify cleanliness, turbidity and oxygen tests are conducted. For turbidity, any number over 500mm is considered a hypoallergenic product. ALLIED down consistently measures well over that hypoallergenic mark and has been used by third party asthma and allergen laboratories as the industry standard for cleanliness. Oxygen testing measures the residual organic matter in the material. The lower the number, the better, with a maximum allowable value of 10.</p></>)
}

function contentFR(){
  return(<><p>Le duvet est un produit naturel qui, comme toute autre matière organique, est sensible aux moisissures, aux acariens et autres allergènes. Cependant, comme il s&#39;agit d&#39;une protéine à base de kératine similaire à nos cheveux, très peu de personnes sont allergiques au duvet lui-même. Ces réactions sont plus souvent le résultat d&#39;acariens et d&#39;autres allergènes présents dans le duvet sale. Le duvet premium d&#39;ALLIED utilise une procédure de lavage unique qui utilise beaucoup moins d&#39;eau que les autres pratiques industrielles, recycle l&#39;eau qui est utilisée et nettoie le duvet en profondeur avec des détergents écologiques approuvés par bluesign®.</p><p>Pour quantifier la propreté, des tests de turbidité et d&#39;oxygène sont effectués. Pour la turbidité, tout nombre supérieur à 500 mm est considéré comme un produit hypoallergénique. Le duvet ALLIED mesure systématiquement bien plus que cette marque hypoallergénique et a été utilisé par des laboratoires tiers spécialisés dans l&#39;asthme et les allergènes comme norme industrielle de propreté. Le test d&#39;oxygène mesure la matière organique résiduelle dans le matériau. Plus le chiffre est faible, mieux c&#39;est, avec une valeur maximale autorisée de 10.</p></>)
}



function CleanContent(props){    
switch(props.activeLang){
  case "fr":
  return contentFR();   
  
  default:
  return contentEN();
}
}
  

const Cleanliness = (props) => {   
	let cleanTitle;
	switch(props.activeLang){
	  case "fr":
	  cleanTitle = "HYPOALLERGÉNIQUE";
	  break;
	  default:
	  cleanTitle = "HYPOALLERGENIC";
	  break;
	}
	
    return (<div className="content-ac cl hide">
    <input
      className="ac-input top"
      id="ac-4afd"
      name="ac-4afd"
      data-id="2400"
      data-slug="CLEANLINESS"
      type="checkbox"
      onChange={ () => props.changeWord('cl clicked') }
    />
    <label className="ac-label" htmlFor="ac-4afd">{ cleanTitle }</label>
    <article className="ac-text ac-4afd"><div className="single-post-interior" tabIndex="0">
        <table>
          <tbody>
            <tr><td>TURBIDITY</td><td>{props.t}MM</td></tr>
            <tr><td>OXYGEN</td><td>{props.o}</td></tr>
          </tbody>
        </table>
        <CleanContent
          activeLang = {props.activeLang}
        />
		       
    </div></article>
  </div>
);
}

export default Cleanliness;